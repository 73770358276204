.contactInfoBox-col {
    position: relative;
}

.contactInfoBox {
    cursor: pointer;
    margin-top: 5px;
    transition: ease all 0.2s;
}

.contactInfoBox:hover {
    background-color: #eee;
}

.contactInfoBox:hover .contactInfoBox-icon-not-checked {
    border: 1px solid green;
}

.contactInfoBox.selected-box {
    border: 1px solid green;
    background-color: #eee;
}

.contactInfoBox-col .contactInfoBox-icon-delete {
    position: absolute;
    top: 16px;
    right: 30px;
    display: block;
    cursor: pointer;
}

.contactInfoBox-col .contactInfoBox-icon-edit {
    position: absolute;
    top: 16px;
    right: 66px;
    display: block;
    cursor: pointer;
}

.contactInfoBox .contactInfoBox-icon-checked {
    position: absolute;
    bottom: 10px;
    right: 15px;
    display: none;
}

.contactInfoBox.selected-box .contactInfoBox-icon-checked { 
    display: block;
}

.contactInfoBox .contactInfoBox-icon-not-checked {
    position: absolute;
    bottom: 10px;
    right: 15px;
    display: block;
    border: 1px solid #aaa;
    border-radius: 50%;
}

.contactInfoBox.selected-box .contactInfoBox-icon-not-checked { 
    display: none;
}

.confirm-box {
    position: fixed !important;
}

.confirm-box__actions button {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    text-decoration: none;
    vertical-align: middle; 
    user-select: none; 
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    background-color: #5869BD;
    border-color: #5869BD;
    line-height: 1.8;
    transition: all 0.2s ease-in-out 0s;
    box-shadow: none !important;
    outline: 0px !important;
    color: #fff;
    border-radius: 0 !important;
    box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
}

.confirm-box__actions button:last-child {
    background-color: #bd5869;
    border-color: #bd5869;
}

.confirm-box__overlay {
    background-color: rgba(0, 0, 0, 0.6) !important;
}
