@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@500;600;900&family=Open+Sans:wght@400;500&display=swap');

*, html, body {
  font-family: 'Open Sans', Arial, sans-serif;
}

html, body, #root {
  height: auto;
  min-height: 100vh;
}

#root {
  white-space: pre-line;
  position: relative;
}

main {
  padding-top: 125px;
  padding-bottom: 130px;
}

h1 {
  font-family: 'Heebo', Helvetica, Arial, Lucida, sans-serif;
  margin-bottom: 20px;
  font-size: 66px;
  font-weight: 900;
  line-height: 1.2em;
}

h2, h3, h4 {
  font-family: 'Open Sans', Helvetica, Arial, Lucida, sans-serif;
  font-weight: 400;
  line-height: 1.2em;
}

h5, h6, p {
  font-weight: 400;
  font-size: 18px;
  font-family: 'Open Sans', Helvetica, Arial, Lucida, sans-serif;
}

#main-nav {
  box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
  padding-bottom: 0.25em;
  padding-top: 0.25em;
  padding-left: 2%;
  padding-right: 2%;
  width: 100%;
  background-color: #fff;
  min-height: 103px;
}

.dropdown-toggle::after {
  display: none;
}

.nav-item {
  margin: 0 5px;
}

.navbar-toggler {
  border: 1px solid #1D1D1B;
  box-shadow: none !important;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  padding: 0;
  transition: ease all 0.3s;
}

.navbar-toggler:hover {
  background-color: #465298;
  color: #fff;
}

.nav-item.highlighted {
  background-color: #ebdc00;
  padding-right: 4px;
  padding-left: 4px;
}

.nav-item.highlighted:hover {
  background-color: #88d0ea;
}

.nav-link {
  line-height: 60px;
}

.nav-link.active {
  color: #5869BD !important;
}

.nav-link svg {
  font-size: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  margin-left: 4px;
}

.dropdown-item {
  color: #1D1D1B;
  font-family: 'Heebo', Helvetica, Arial, Lucida, sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 30px;
}

.dropdown-item:hover {
  color: #666;
  transition: ease all 0.25s;
}

.dropdown-item:active {
  background-color: #5869BD;
  color: #fff;
}

.btn-primary, .btn-primary:focus {
  background-color: #5869BD;
  border-color: #5869BD;
  -webkit-transition: all 0.2s ease-in-out 0s;
  -o-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
  box-shadow: none;
}

.btn-primary:hover, .btn-primary:active {
  background-color: #252f5f;
  box-shadow: none !important;
  outline: 0px !important;
}

.btn-primary.disabled, .btn-primary:disabled {
  cursor: not-allowed;
  pointer-events: all !important;
  background-color: #5869BD;
  border-color: #5869BD;
  background: repeating-linear-gradient(45deg, #606dbc, #606dbc 10px, #465298 10px, #465298 20px);
}

.btn-danger, .btn-danger:focus {
  background-color: #bd5869;
  border-color: #bd5869;
  -webkit-transition: all 0.2s ease-in-out 0s;
  -o-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
  box-shadow: none;
}

.btn-danger:hover, .btn-danger:active {
  background-color: #6b2b35;
  box-shadow: none !important;
  outline: 0px !important;
}

.form-control:focus, .form-select:focus {
  box-shadow: none;
}

.btn, .btn-primary, .btn-danger, .form-control, .form-select {
  border-radius: 0 !important;
  box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
}

.nav-link, .nav-tabs .nav-link {
  border-radius: 0;
}

.dropdown-menu {
  border-radius: 0;
  border-top: 3px solid #5869BD;
}

.input-group-text {
  box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
}

.bg-lightcolor {
  background-color: #5869bd67;
}

table.table-bordered {
  box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
}

.loader-fullpage {
  height: 0;
  width: 100%;
  position: fixed;
  z-index: 10000000;
  left: 0;
  top: 0;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.9);
  overflow-x: hidden;
  transition: 0.5s;
  text-align: center;
  color: #fff;
  transition: ease all 1s;
}

.loader-fullpage.isLoading {
  height: 100%;
}

.loader-fullpage.isLoading .loader-container {
  opacity: 1;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  transition: ease all .2s;
}

.loader-fullpage .loader-container {
  opacity: 0;
}

.loader-fullpage .spinner-border {
  width: 8rem;
  height: 8rem;
  border-width: 1em;
}

.carousel-inner {
  padding-top: 24px;
  padding-bottom: 24px;
}

.nav-link {
  color: #1D1D1B;
  font-family: 'Heebo', Helvetica, Arial, Lucida, sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 16px;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active, .nav-link:focus, .nav-link:hover {
  color: #666;
  transition: ease all 0.15s;
}

.alert-success {
  border: none;
  border-radius: 0;
  background: rgb(66, 209, 144);
  background: linear-gradient(90deg, rgba(66, 209, 144, 0.5) 0%, rgba(129, 224, 182, 0.5) 30%, rgba(255, 255, 255, 1) 100%);
}

.form-control, .form-select, .btn {
  line-height: 1.8;
}

.language-flags {
  margin-top: -3px;  
  display: none; /* currently not shown */
}

.dropdown-menu.show .language-flags{  
  display: inline-block;
  opacity: 0; /* currently not shown */
}

.normal-case {
  text-transform: none;
}

.small-text {
  font-size: 40px;
  line-height: 20px;
}

footer {
  padding-bottom: 57px;
  padding-top: 38px;
  background-color: #5869BD;
  border: 0 solid #333;
  border-color: rgba(0, 0, 0, 0) #1D1D1B rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
  border-right-width: 5vw;
  box-shadow: inset -4vw 0px 0px 0px #ffffff;
  color: #fff;
  position: absolute;
  bottom: 0;
  width: 100%;
  transition: ease all 0.4s;
}

footer a,
footer a:hover {
  color: #fff;
}

footer .container-md {
  position: absolute;
  right: 50%;
  left: 50%;
  transform: translateX(-50%);
}

footer span {
  font-family: 'Heebo', Helvetica, Arial, Lucida, sans-serif;
  font-weight: 700;
  letter-spacing: 3px;
  line-height: 2em;
  font-size: 14px;
}

.nav-tabs .nav-link {
  line-height: 25px;
}

button.btn.btn-link {
  box-shadow: none;
}

.logo {
  width: 250px;
}

.container-lg {
  max-width: unset; 
  padding-left: 0; 
  padding-right: 0;
}

@media (min-width: 1400px) {
  .container, .container-md, .container-sm, .container-xl, .container-xxl {
    max-width: 1140px;
  }
}

@media (max-width: 1000px) {
  .nav-link {
    line-height: 30px;
  }
}

@media (max-width: 770px) {
  main {
    padding-top: 60px;
  }

  h1 {
    margin-bottom: 15px;
    font-size: 35px;
  }

  h5, h6, p {
    font-size: 14px;
  }
}