.pricingTable {
    margin-top: 70px;
    margin-bottom: 60px;
    padding: 50px 15px;
    text-align: center;
    border-radius: 1px;
    border-color: rgb(33, 37, 41);
    border: 1px solid rgba(33, 37, 41, 0.5);
    -webkit-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
}

.pricingTablePromoted {
    margin-top: 25px;
    border: 3px solid #5869BD;
}

.pricingTable .title {
    font-size: 24px;
    font-weight: 600;
}

.pricingTable .price-month {
    font-size: 16px;
    font-weight: 500;
    margin-top: 5px;
}

.pricingTable .price-value {
    font-size: 130px;
    line-height: 112px;
    position: relative;
    margin: 20px 0;
    color: #5869bd42;
    font-family: 'Heebo', Helvetica, Arial, Lucida, sans-serif;
}

@media only screen and (max-width:1200px) {
    .pricingTable .price-value {
        font-size: 100px;
    }
}

.pricingTable .price-value .value-bg {
    display: inline-block;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    color: #5869BD;
    font-weight: 600;
    font-size: 40px;
    line-height: 60px;
}

.pricingTable .pricing-content {
    list-style: none;
    padding: 0;
    margin: 0 0 20px 0;
}

.pricing-content-border>li {
    border: 1px solid #eee;
    margin-bottom: 10px;
}

.pricingTable .pricing-content li {
    line-height: 20px;
    margin: 20px 0;
    font-size: 0.95rem;
}

@media only screen and (max-width: 990px) {
    .pricingTable {
        margin-bottom: 30px;
    }
}

@media only screen and (max-width: 767px) {
    .pricingTable {
        margin-bottom: 50px;
    }
}